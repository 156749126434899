import React, { useState } from "react";
import Header from "../Header/Header";
import Input from "../Input/Input";
import Loading from "../Loading/Loading";
import api from "../../axios/config";
import ResponseEngConicaAPI from "../ResponseEngConicaAPI/ResponseEngConicaAPI";

const PayloadEngConica = () => {
  const tokenAuthenticated = sessionStorage.getItem("token");
  const [angle, setAngle] = useState(15);
  const [axisAngle, setAxisAngle] = useState(90);
  const [teethz1, setTeethz1] = useState("");
  const [teethz2, setTeethz2] = useState("");
  const [module, setModule] = useState("");
  const [diameterExternal1, setDiameterExternal1] = useState("");
  const [diameterExternal2, setDiameterExternal2] = useState("");
  const [moduleOrdiameter, setModuleOrdiameter] = useState("Módulo");
  const [crownTeeth1, setCrownTeeth1] = useState("");
  const [valuesAPI, setValuesAPI] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mostraModal, setMostraModal] = useState(false);

  const postApiCalculatedEngConica = async () => {
    const payload = {
      angle: Number(angle),
      teeth1: Number(teethz1),
      teeth2: Number(teethz2),
      ...(moduleOrdiameter === "Módulo"
        ? { module: Number(module) }
        : {
            diameter1: Number(diameterExternal1),
            diameter2: Number(diameterExternal2),
          }),

      axis_angle: Number(axisAngle),
      crown_teeth1: Number(crownTeeth1),
      crown_teeth2: Number(crownTeeth1),
    };
    setLoading(true);
    setValuesAPI([]);
    await api
      .post("engine/calc_conical", payload, {
        headers: {
          token: tokenAuthenticated,
        },
      })
      .then((response) => {
        const data = response.data;
        setValuesAPI(data);
      })
      .catch((error) => {
        console.error("ERRO ->", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Header />
      <h1 style={{ textAlign: "center", marginTop: "10px", fontSize: "30px" }}>
        Cálculo: <strong>Engrenagem Cônica</strong>
      </h1>
      <div className='container-all-p'>
        <div className='mt-2 mb-2'>
          <div className='flex flex-row'>
            <label className='block mr-3'>Ângulo Pressão:</label>
            <label className='inline-flex items-center mr-4'>
              <input
                type='radio'
                value='15'
                checked={angle === 15}
                onChange={(event) => setAngle(parseInt(event.target.value))}
                className='form-radio text-indigo-600 h-5 w-5 cursor-pointer'
              />
              <span className='ml-2 font-bold'>15</span>
            </label>
            <label className='inline-flex items-center'>
              <input
                type='radio'
                value='20'
                checked={angle === 20}
                onChange={(event) => setAngle(parseInt(event.target.value))}
                className='form-radio text-indigo-600 h-5 w-5 cursor-pointer'
              />
              <span className='ml-2 font-bold'>20</span>
            </label>
          </div>
        </div>
        <div className='div_inputs'>
          <div className='div_input_span'>
            <label className='block text-gray-700 text-sm font-bold'>
              Diametro / Módulo:*
            </label>
            <select
              value={moduleOrdiameter}
              onChange={(e) => setModuleOrdiameter(e.target.value)}
              className='py-2 px-3 border border-gray-300 focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full h-10'
            >
              <option value='Diametro'>Diametro</option>
              <option value='Módulo'>Módulo</option>
            </select>
          </div>
          {moduleOrdiameter === "Módulo" && (
            <div className='div_input_span'>
              <Input
                label={"Módulo :*"}
                value={module}
                placeholder={"Módulo"}
                onChange={(e) => setModule(e.target.value)}
              />
            </div>
          )}
          {moduleOrdiameter === "Diametro" && (
            <>
              <div className='div_input_span'>
                <Input
                  label={"Diametro Externo Z1:*"}
                  value={diameterExternal1}
                  placeholder={"Diametro Externo Z1"}
                  onChange={(e) => setDiameterExternal1(e.target.value)}
                />
              </div>
              <div className='div_input_span'>
                <Input
                  label={"Diametro Externo Z2:*"}
                  value={diameterExternal2}
                  placeholder={"Diametro Externo Z2"}
                  onChange={(e) => setDiameterExternal2(e.target.value)}
                />
              </div>
            </>
          )}
          <div className='div_input_span'>
            <Input
              label={"Número de dentes(Cor. Div):*"}
              value={crownTeeth1}
              placeholder={"(Z) Coroa Divisor"}
              onChange={(e) => setCrownTeeth1(e.target.value)}
            />
          </div>
          <div className='div_input_span'>
            <Input
              label={"Dentes do Z1:*"}
              value={teethz1}
              placeholder={"Z1"}
              onChange={(e) => setTeethz1(e.target.value)}
            />
          </div>
          <div className='div_input_span'>
            <Input
              label={"Dentes do Z2:*"}
              value={teethz2}
              placeholder={"Z2"}
              onChange={(e) => setTeethz2(e.target.value)}
            />
          </div>
          <div className='div_input_span'>
            <Input
              label={"Ângulo do eixo:*"}
              value={axisAngle}
              placeholder={"Ângulo do eixo"}
              onChange={(e) => setAxisAngle(e.target.value)}
            />
          </div>
        </div>

        <div className='my-2 w-1/2 lg:w-1/4'>
          <button
            className={`block w-full mt-4 py-2 rounded-2xl font-semibold mb-2 ${
              teethz1 === "" ||
              teethz2 === "" ||
              axisAngle === "" ||
              crownTeeth1 === "" ||
              (moduleOrdiameter === "Diametro"
                ? diameterExternal1 === "" || diameterExternal2 === ""
                : module === "")
                ? "bg-gray-400 text-white  cursor-not-allowed"
                : "bg-blue-600 text-white"
            }`}
            onClick={() => {
              postApiCalculatedEngConica();
              setMostraModal(true);
            }}
            disabled={
              teethz1 === "" ||
              teethz2 === "" ||
              axisAngle === "" ||
              crownTeeth1 === "" ||
              (moduleOrdiameter === "Diametro"
                ? diameterExternal1 === "" || diameterExternal2 === ""
                : module === "")
            }
          >
            Calcular
          </button>

          {loading && <Loading />}
          {!loading && mostraModal && (
            <ResponseEngConicaAPI
              valuesAPI={valuesAPI}
              setMostraModal={setMostraModal}
              angle={angle}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PayloadEngConica;
